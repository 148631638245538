import { Component } from '@angular/core';
import { TitleService } from '../shared/services/title.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  constructor(private titleService: TitleService) {
    this.titleService.setTitle('Page Not Found');
  }
}
