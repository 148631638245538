<section *ngIf="!cardData?.fields?.titleOverImage ; else titleOverImage" class="cardContainer">
  <div
    [ngClass]="[cardData?.fields?.style === 'oval'? 'textImageExtended' : 'normalCard',cardData?.fields?.cardHeight?cardData?.fields?.cardHeight:'medium']"
    class="card">
    <div *ngIf="cardData?.fields?.style === 'oval' ; else imageWrapper" class="cardImageWrapperOval">
      <a [href]="cardData?.fields?.link?.fields?.url" tabindex="0">
        <img *ngIf="cardData?.fields?.image?.fields?.file?.url" [alt]="cardData?.fields?.image?.fields?.title"
             [src]="cardData?.fields?.image?.fields?.file?.url" class="cardImage">
      </a>
      <div>{{cardData?.fields?.title}}</div>
      <div>{{cardData?.fields?.subHeading}}</div>
    </div>

    <div class="cardContent">
      <div [appRenderRichText]="cardData?.fields?.body">

      </div>
      <!-- <button *ngIf="btnLabel && cardData?.fields?.link?.fields?.url"
        (click)='navigateToURL(cardData?.fields?.link?.fields?.url)'>{{btnLabel}}</button> -->

      <div *ngFor="let picture of cardData?.fields?.pictureCollection " class="pictureCollectionImg">
        <img [alt]="picture?.fields?.title" [src]="picture.fields.file.url" class="pictureCollectionImg">
      </div>
      <div *ngIf="cardData?.fields?.iframeUrl" class="iframeContainer">
        <iframe [src]="safeIframeSrcConverter()" allowfullscreen class="iframeContent" frameborder="0" mozallowfullscreen
                webkitallowfullscreen></iframe>
      </div>
    </div>
    <div *ngIf="cardData?.fields?.showCalendly">

      <app-calendly-widget [btnLabel]="cardData?.fields?.buttonLabel|| ''"
                           [sharedData]="cardData?.fields?.sharedData|| ''"></app-calendly-widget>
    </div>

  </div>

  <div class="cardBtn">
    <button (click)='navigateToURL(cardData?.fields?.link?.fields?.url)'
            *ngIf="btnLabel && cardData?.fields?.link?.fields?.url">{{btnLabel}}</button>
  </div>
</section>
<ng-template #titleOverImage>
  <div class="titleOverContainer">
    <div class="centeredText">{{cardData?.fields?.title}}</div>
    <img *ngIf="cardData?.fields?.image?.fields?.file?.url" [alt]="cardData?.fields?.title"
         [src]="cardData?.fields?.image?.fields?.file?.url"
         class="cardImage">
  </div>

</ng-template>

<ng-template #imageWrapper>
  <div class="cardImageWrapper">
    <h4 *ngIf="cardData?.fields?.title">{{cardData?.fields?.title}}</h4>
    <h2 *ngIf="cardData?.fields?.subHeading">{{cardData?.fields?.subHeading}}</h2>
    <a [href]="cardData?.fields?.link?.fields?.url" tabindex="0">
      <img *ngIf="cardData?.fields?.image?.fields?.file?.url" [alt]="cardData?.fields?.title"
           [src]="cardData?.fields?.image?.fields?.file?.url"
           class="cardImage" [ngClass]="cardData?.fields?.imageHeight">
    </a>

  </div>
</ng-template>
