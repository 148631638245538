// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from './environment.interface';

export const environment: Environment = {
  baseUrl: 'http://34.110.241.210/api/',
  production: false,
  gtagID:'G-JBCP624454',
  contentful: {
    space: '3h8j9vctv5nr',
    contentfulBaseURL: 'https://cdn.contentful.com',
    contentfulProxyBaseURL: 'http://34.110.241.210/api/',
    environment: 'master',
    accessToken: 'ndBnIAIz2O5JGEe6tqni4GLhbVE7sTmRZGnGIgrX6vc'
  },
  urlToShowPreviewOptions: 'localhost:4200',
  innovatorsFoodBackendUrl: 'https://black-api-backend-k2e7j3zuba-ts.a.run.app',
  googleClientId: '614512994682-q8oebrbrtpmmurlnc958jd8ole4igi37.apps.googleusercontent.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
