import {
  Component, ElementRef, HostListener, Input, ViewChild 
} from '@angular/core';

export {};
declare global {
  interface Window {
    Calendly: any;
  }
}

@Component({
  selector: 'app-calendly-widget',
  templateUrl: './calendly-widget.component.html',
  styleUrls: ['./calendly-widget.component.scss']
})
export class CalendlyWidgetComponent {
  @Input() btnLabel: string = '';

  @Input() sharedData: string = '';

  @ViewChild('container') container!: ElementRef;

  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    if (this.isCalendlyEvent(event)) {
      /* Example to get the name of the event */
      console.log('Event name:', event.data.event);

      /* Example to get the payload of the event */
      console.log('Event details:', event.data.payload);
    }
  }

  isCalendlyEvent(e: any) {
    return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
  }

  calendlyPopupWidget() {
    window.Calendly.initPopupWidget({
      url: 'https://calendly.com/tpalladan',
      prefill: {
        name: this.sharedData,
        email: '',
        customAnswers: {
          a1: '',
          a2: ''
        }
      }
    });
    return false;
  }
}
