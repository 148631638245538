import { Injectable } from '@angular/core';
import { ContentfulService } from '../shared/services/contentful.service';
import { IFdinHomePage, IFdinRecipeCard } from '../shared/interfaces/contentful';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private cms: ContentfulService) {
  }

  /**
   * Return nav contents.
   */
  async getNavContent() {
    return (await firstValueFrom(this.cms.getContent('fdinHomePage', { include: 10 })))[0] as IFdinHomePage;
  }

  /**
   * Return cooking class.
   *
   * @param {object} fetchQuery - To pass the fetchQuery.
   */
  async getCookingClass(fetchQuery: object,) {
    // api.contentful.com/spaces/3h8j9vctv5nr/environments/master/entries?content_type=fdinRecipeCard&fields.pageCategory.sys.id=6hv17Q8VhgRpCemDANgBaO&limit=40&order=-sys.updatedAt&skip=0&sys.archivedAt%5Bexists%5D=false
    return (await firstValueFrom(this.cms.getContent('fdinRecipeCard', fetchQuery))) as IFdinRecipeCard[];
  }
}
