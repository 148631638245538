import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stay-connected',
  templateUrl: './stay-connected.component.html',
  styleUrls: ['./stay-connected.component.scss']
})
export class StayConnectedComponent implements OnInit {
  @Input() title: string = '';

  @Input() textLink: string = '';

  @Input() icon: string = '';

  iconPath: string = '';

  ngOnInit(): void {
    this.iconPath = `assets/icon/${this.icon}`;
  }
}
