import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IFdinFooter } from '../shared/interfaces/contentful';
import { ContentfulService } from '../shared/services/contentful.service';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  constructor(private cms: ContentfulService) {
  }

  /**
   * Return nav contents.
   */
  async getContent() {
    return (await firstValueFrom(this.cms.getContent('fdinFooter', { include: 10, 'fields.slug': 'footer' })))[0] as IFdinFooter;
  }
}
