import {
  Component, Input, OnChanges, OnInit, SimpleChanges,
} from '@angular/core';

import { IFdinRecipeCard } from '../../interfaces/contentful';
import { ContentfulService } from '../../services/contentful.service';
import { CommonService } from '../../services/common.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})
export class SearchViewComponent implements OnChanges, OnInit {
  @Input() calledFrom: string = '';

  @Input() categoryName: string = '';

  @Input() showSideNav: boolean = true;

  @Input() btnLabel?: string;

  searchResults: IFdinRecipeCard[] = [];

  categoryList: any = [];

  sidePanelList: any;

  checkedCategoryList: any;

  allSelected: boolean = true;

  allSelectedLabel: string = '';

  pageCode: string = '';

  public searchString: string = '';

  constructor(
    private cms: ContentfulService,
    private commonService: CommonService,
  ) {
  }

  ngOnInit(): void {
    this.fetchListItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryName']) {
      this.fetchListItems();
    }
  }

  async search() {
    this.getData(this.pageCode, false);
    const fetchQuery: any = { include: 10, limit: 10, 'fields.pageCategory.sys.id': this.pageCode };
    const categoryCode = this.getCheckedItemList() || '';
    if (categoryCode) {
      fetchQuery['fields.categoryList.sys.id[in]'] = categoryCode;
    }
    if (this.searchString) {
      fetchQuery['query'] = this.searchString;
    }
    this.getContent(fetchQuery, 'fdinRecipeCard');
  }

  generateCategoryList(list: any, categoryName: any) {
    this.categoryList = [];
    list.forEach((value: any, key: any) => {
      let selected = false;
      if (categoryName.toLowerCase().trim() === value.fields?.categoryName.toLowerCase().trim()) selected = true;
      const field = { id: key, value: value.fields?.categoryName, isSelected: selected };
      if (value.fields?.categoryName.toLowerCase().trim() !== 'cooking-classes') {
        this.categoryList.push(field);
      }
    });
  }

  async getData(categoryID: string, generateCategoryList:boolean = true) {
    const fetchQuery = categoryID ? { 'fields.pageCategory.sys.id': categoryID } : {};
    if (generateCategoryList) {
      this.sidePanelList = (await this.cms.getContent('fdinRecipesCategory', fetchQuery));
      this.generateCategoryList(this.sidePanelList, this.categoryName);
    }
  }

  async getCardList() {
    this.getData(this.pageCode, true);
    const fetchQuery: any = { include: 10, limit: 10, 'fields.pageCategory.sys.id': this.pageCode };
    const categoryCode = this.commonService.getCategoryListCode(this.categoryName) || '';
    if (categoryCode) {
      fetchQuery['fields.categoryList.sys.id[in]'] = categoryCode;
    }
    this.getContent(fetchQuery, 'fdinRecipeCard');
  }

  async fetchListItems() {
    this.allSelectedLabel = this.calledFrom;
    this.pageCode = this.commonService.getBlogPageCategoryCode(this.calledFrom) || '';
    this.getCardList();
  }

  checkUncheckAll() {
    this.categoryList.every((item: any) => item.isSelected = false);
    this.getCheckedItemList();
  }

  checkAllSelected() {
    this.categoryList.every((item: any) => item.isSelected = true);
    this.getCheckedItemList();
  }

  selectAll() {
    // !this.allSelected ? this.checkUncheckAll() : this.checkAllSelected();
    // this.allSelected = true;
    this.checkAllSelected();
  }

  getCheckedItemList() {
    this.checkedCategoryList = [];
    let category: string = '';
    this.checkedCategoryList = this.categoryList.filter((t: { isSelected: boolean; }) => t.isSelected);
    this.checkedCategoryList.forEach((value: any) => {
      category = category === '' ? `${this.commonService.getCategoryListCode(value.value)}` : `${category},${this.commonService.getCategoryListCode(value.value)}`;
    });
    return category;
  }

  /**
   * Return nav contents.
   *
   * @param  {object} fetchQuery - to pass the query.
   * @param {string} contentType - To pass the content type.
   */
  async getContent(fetchQuery: object, contentType: string) {
    this.searchResults = (await firstValueFrom(this.cms.getContent(contentType, fetchQuery))) as IFdinRecipeCard[];
  }
}
