import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IFdinBlogPost } from '../shared/interfaces/contentful';
import { ContentfulService } from '../shared/services/contentful.service';
import { TitleService } from '../shared/services/title.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  blogPost!: IFdinBlogPost;

  constructor(
    private cms: ContentfulService,
    private titleService: TitleService

  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Resources');
    this.getContent();
  }

  /**
   * Return recipe contents.
   *
   */
  async getContent() {
    this.blogPost = (await firstValueFrom(this.cms.getContent('fdinBlogPost', { 'fields.slug': 'resources' })))[0] as IFdinBlogPost;

  }
}
