<div [ngClass]="wrapContent?'wrapContent':''" class="list">
  <ng-container *ngIf="ShowSearchResults">
    <div *ngFor="let cardCollection of searchResults " [ngClass]="textOverTiles?'textOverTilesListItem':'listItem'">
      <div [ngClass]="parentBackground?'ContentBackground':''" class="listContent">
        <app-card-layout [cardData]="cardCollection?.fields?.recipe"></app-card-layout>
        <div class="btnWrapper">
          <button (click)='navigateToURL(cardCollection?.fields?.recipe?.fields?.link?.fields?.url)'
                  *ngIf="btnLabel || cardCollection?.fields?.recipe?.fields?.link?.fields?.url"
                  class="cardBtn">{{btnLabel}}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showBlogPostContent">
    <div *ngFor="let cardCollection of blogPostContent " [ngClass]="textOverTiles?'textOverTilesListItem':'listItem'">
      <div [ngClass]="parentBackground?'ContentBackground':''" class="listContent">
        <app-card-layout [cardData]="cardCollection"></app-card-layout>
        <div *ngIf="btnLabel || cardCollection?.fields?.link?.fields?.url" class="btnWrapper">
          <button (click)='navigateToURL(cardCollection?.fields?.link?.fields?.url)' class="cardBtn">{{btnLabel}}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
