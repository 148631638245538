<div *ngIf="blogPageContent?.fields?.descriptionOverFullImage?.fields?.media?.fields?.file?.url "
     [style.background-image]="'url(' + blogPageContent?.fields?.descriptionOverFullImage?.fields?.media?.fields?.file?.url + ')'"
     class="heroImage">

  <div class="heroTitle">
    <div [appRenderRichText]="blogPageContent?.fields?.descriptionOverFullImage?.fields?.text" class="content">

    </div>
  </div>
</div>
<section class="blogSection">
  <div [appRenderRichText]="blogPageContent?.fields?.leftContent" class="blogContent">
  </div>
  <div class="cardWrapContainer">
    <app-card-layout [cardData]="blogPageContent?.fields?.recipeCard"></app-card-layout>

    <div [appRenderRichText]="blogPageContent?.fields?.rightContent" class="child"></div>
  </div>

</section>

<ng-container>
  <section class="swiperContainer">
    <div class="title">
      <h4>MORE</h4>
      <h2>Our Recent Blogs</h2>
    </div>
    <swiper-container [navigation]="{}"
                      [pagination]="{ clickable: true }"
                      [scrollbar]="{ draggable: true }"
                      [slidesPerView]="3"
                      [spaceBetween]="50"
                      [watchSlidesProgress]="true">
      <swiper-slide *ngFor="let cardCollection of StoriesTrendsContent" class="cardContainer">
        <app-card-layout [btnLabel]="'Read More'" [cardData]="cardCollection?.fields?.recipe"
                         class="swiperChild"></app-card-layout>
      </swiper-slide>
    </swiper-container>
  </section>
</ng-container>
