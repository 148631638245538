import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IFdinHomeContent } from 'src/app/shared/interfaces/contentful';

@Component({
  selector: 'app-home-data',
  templateUrl: './home-data.component.html',
  styleUrls: ['./home-data.component.scss']
})
export class HomeDataComponent implements OnInit {
  @Input() homeContent?: IFdinHomeContent;

  safeSrc?: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (this.homeContent?.fields?.video) {
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.homeContent?.fields?.video);
    }
  }

  navigateToURL(url: any) {
    this.router.navigateByUrl(url);
  }
}
