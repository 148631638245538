<section [ngClass]="[contentPost?.fields?.mediaPosition]" class="contentPost">
  <ng-container *ngIf="contentPost?.fields?.title1 || contentPost?.fields?.contentText">

    <div [ngClass]="[contentPost?.fields?.contentPostBackground]" class="contentPostChild ">
      <div [ngClass]="{
        'textCenter': contentPost?.fields?.textPosition  === 'center',
        'textLeft': contentPost?.fields?.textPosition  === 'left',
        'textRight': contentPost?.fields?.textPosition  === 'right',

    }" class="textContent">
        <h1 *ngIf="contentPost?.fields?.title1">{{contentPost?.fields?.title1}}</h1>
        <div *ngIf="contentPost?.fields?.contentText">
          <div [appRenderRichText]="contentPost?.fields?.contentText" class="content"></div>
        </div>


      </div>
      <div  class="pageCenter" *ngIf="contentPost?.fields?.contentText2" [ngClass]="[ contentPost?.fields?.textContentBackground]">
        <div [appRenderRichText]="contentPost?.fields?.contentText2" class="content"></div>
      </div>
    </div>

  </ng-container>

  <div *ngIf="contentPost?.fields?.image?.fields?.file?.url || contentPost?.fields?.iframeUrl"
       [ngClass]="[ contentPost?.fields?.mediaSize]"
       class="contentPostChild">
    <div *ngIf="contentPost?.fields?.image?.fields?.file?.url || contentPost?.fields?.iframeUrl"
         class="contentPostChild">

      <img *ngIf="contentPost?.fields?.image?.fields?.file?.url" [src]="contentPost?.fields?.image?.fields?.file?.url"
           alt="Image"
           class="cardImage">
    </div>

    <div *ngIf="contentPost?.fields?.iframeUrl" class="iframeContainer">
      <iframe [src]="safeIframeSrc"
              allowfullscreen
              class="iframeContent"
              frameborder="0"
              mozallowfullscreen
              webkitallowfullscreen></iframe>
    </div>
  </div>


</section>
<!--
<div class="box" >
  <div  *ngIf="contentPost?.fields?.contentPostBackground === 'customBackground'" [style.background-image]="'url(' +contentPost?.fields?.customBackground?.fields?.file?.url+ ')'">dsfds</div>
</div> -->
