<section class="recipePage">
  <div class="recipeContent">
    <div class="image">
      <img *ngIf="recipePageContent?.fields?.recipeImage?.fields?.file?.url" [alt]="recipePageContent?.fields?.heading"
           [src]="recipePageContent?.fields?.recipeImage?.fields?.file?.url" class="recipeImage">
    </div>


    <h1>{{recipePageContent?.fields?.heading}}</h1>

    <div [appRenderRichText]="recipePageContent?.fields?.introduction">

    </div>
    <div class="socialContainer">
      <div class="socialLink" id="socialFbContainer">
        <a [href]="'http://www.facebook.com/sharer.php?' + pageUrl" target="_blank">
          <img class="socialImg" src="assets/icon/facebook-square-black.png"> </a>
      </div>
      <div class="socialLink" id="socialPinterestContainer">
        <a data-pin-custom="true" data-pin-do="buttonBookmark" href="https://www.pinterest.com/pin/create/button/"
           target="_blank">
          <img class="socialImg" src="assets/icon/pinterest-square-black.png"> </a>
      </div>
      <div class="socialLink" id="socialEmailContainer">
        <a
          [href]="'mailto:?subject=Breakfast%20Granola%20Recipe&amp;body=Hi,%20See%20this%20great%20recipe%20I%20found%20on%20the%20Food%20Innovators%20page%20'+pageUrl"
          target="_blank">
          <img class="socialImg" src="assets/icon/envelope-square-black.png"> </a>
      </div>
      <div class="socialLink" id="socialPrintContainer">
        <a
          [href]="'mailto:?subject=Breakfast%20Granola%20Recipe&amp;body=Hi,%20See%20this%20great%20recipe%20I%20found%20on%20the%20Food%20Innovators%20page%20'+pageUrl"
          target="_blank">
          <img class="socialImg" src="assets/icon/print-black.png"> </a>
        <!--
                    <a id="social-print-link" href="javascript:;" onclick="window.print()"> <img class="social-img" src="/content/dam/food-innovators/icons/print-black.png" alt="Print"> </a>  -->
      </div>
    </div>
    <div class="container">
      <div [appRenderRichText]="recipePageContent?.fields?.leftContent" class="child"></div>
      <div [appRenderRichText]="recipePageContent?.fields?.rightContent" class="child"></div>

    </div>
    <div class="image">
      <img *ngIf="recipePageContent?.fields?.summaryImage?.fields?.file?.url" [alt]="recipePageContent?.fields?.heading"
           [src]="recipePageContent?.fields?.summaryImage?.fields?.file?.url"
           class="summaryImage">
    </div>
  </div>
</section>


<section class="swiperContainer">
  <swiper-container [navigation]="{}"
                    [pagination]="{ clickable: true }"
                    [scrollbar]="{ draggable: true }"
                    [slidesPerView]="3"
                    [spaceBetween]="50"
                    [watchSlidesProgress]="true">
    <swiper-slide *ngFor="let cardCollection of recipeCardContent" class="cardWrapContainer">
      <app-card-layout [btnLabel]="'Book now'" [cardData]="cardCollection?.fields?.recipe"
                       class="swiperChild"></app-card-layout>
    </swiper-slide>
  </swiper-container>
</section>
