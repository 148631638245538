import { Component } from '@angular/core';
import { IFdinFooter } from 'src/app/shared/interfaces/contentful';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FooterService } from '../../footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  footerContent?: IFdinFooter;

  constructor(
    private _footerService: FooterService,
    private authService: AuthService,
  ) {
    this.getContent();
  }

  /**
   * Get nav items from the CMS.
   */
  async getContent() {
    this.footerContent = await this._footerService.getContent();
  }

  showContent(): boolean {
    return this.authService.isLoggedIn();
  }
}
