<div [ngClass]="[ homeContent?.fields?.containerSize, homeContent?.fields?.mediaPosition]" class="homeColum">
  <div [ngClass]="homeContent?.fields?.backgroundTransparent?'backgroundTransparent':'backgroundSolid'" class="homeRow">
    <div class="homeData">
      <h5>{{homeContent?.fields?.title}}</h5>
      <h1>{{homeContent?.fields?.subTitle}}</h1>
      <div [appRenderRichText]="homeContent?.fields?.body" class="blogContent">
      </div>
      <button (click)='navigateToURL(homeContent?.fields?.url?.fields?.url)'
              *ngIf="homeContent?.fields?.url?.fields?.url"
              class="btnTransparent">{{homeContent?.fields?.url?.fields?.displayText}}</button>
    </div>

  </div>
  <div [ngClass]="homeContent?.fields?.backgroundTransparent?'mediaTransparent':'mediaSolid'" class="homeRow">

    <img (click)='navigateToURL(homeContent?.fields?.url?.fields?.url)'
         *ngIf="homeContent?.fields?.image?.fields?.file?.url"
         [ngClass]="{imageLink: homeContent?.fields?.url?.fields?.url }"
         [src]="homeContent?.fields?.image?.fields?.file?.url"
         alt="food"
         class="cardImage">
    <div *ngIf="homeContent?.fields?.video" class="videoContainer">
      <iframe [src]="safeSrc" allowfullscreen class="iframeContainer" frameborder="0" mozallowfullscreen
              webkitallowfullscreen></iframe>
    </div>
  </div>
</div>
