import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ContentfulService } from '../shared/services/contentful.service';
import { IFdinBlogPost } from '../shared/interfaces/contentful';
import { TitleService } from '../shared/services/title.service';

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss']
})
export class WhatWeDoComponent implements OnDestroy {
  whatWeDoContent?: IFdinBlogPost;

  private componentDestroyed = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cms: ContentfulService,
    private titleService: TitleService

  ) {
    this.watchForRouteChange();
  }

  /**
   * If the user clicks a different blog link, the route will change but since the target component is the same, the page won't reload on its own.
   */
  watchForRouteChange() {
    this.route.paramMap
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.getContent();
      });
  }

  /**
   * Return nav contents.
   *
   */
  async getContent() {
    // first get the page slug
    const slug = this.route.snapshot.paramMap.get('category-name');
    this.titleService.setTitle(`What we do | ${slug}`);

    this.whatWeDoContent = (await firstValueFrom(this.cms.getContent('fdinBlogPost', { 'fields.slug': `What-we-do/${slug}` })))[0] as IFdinBlogPost;
    // if we couldn't find a matching post, send them to the 404 page
    if (!this.whatWeDoContent) {
      this.router.navigateByUrl('/pages/not-found');
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}
