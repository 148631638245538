import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IFdinBlogPost } from '../shared/interfaces/contentful';
import { ContentfulService } from '../shared/services/contentful.service';
import { TitleService } from '../shared/services/title.service';

@Component({
  selector: 'app-our-kitchens',
  templateUrl: './our-kitchens.component.html',
  styleUrls: ['./our-kitchens.component.scss']
})
export class OurKitchensComponent implements OnInit {
  blogPost!: IFdinBlogPost;
  private setTitle ='Our kitchens';
  constructor(
    private cms: ContentfulService,
    private titleService: TitleService
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.setTitle);
    this.getContent();
  }

  /**
   * Return recipe contents.
   *
   */
  async getContent() {
    this.blogPost = (await firstValueFrom(this.cms.getContent('fdinBlogPost', { 'fields.slug': 'ourKitchens' })))[0] as IFdinBlogPost;
  }
}
