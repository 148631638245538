import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { IFdinBlogPost, IFdinRecipeCard } from 'src/app/shared/interfaces/contentful';
import { CommonService } from 'src/app/shared/services/common.service';
import { ContentfulService } from 'src/app/shared/services/contentful.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-cooking-school',
  templateUrl: './cooking-school.component.html',
  styleUrls: ['./cooking-school.component.scss']
})
export class CookingSchoolComponent implements OnDestroy {
  blogPost!: IFdinBlogPost;

  cookingClasses: IFdinRecipeCard[] = [];

  showCookingClasses: boolean = false;

  private componentDestroyed = new Subject<void>();

  private setTitle ='cooking classes';
  constructor(
    private cms: ContentfulService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private titleService: TitleService
  ) {
    this.watchForRouteChange();
    register();
  }

  /**
   * If the user clicks a different blog link, the route will change but since the target component is the same, the page won't reload on its own.
   */
  watchForRouteChange() {
    this.route.paramMap
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.getContent();
      });
  }

  /**
   * Return cooking contents.
   *
   */
  async getContent() {
    // first get the page slug

    const slug = this.route.snapshot.paramMap.get('category-name');
    if (slug === 'cooking-classes' || slug === 'home') {
      const pageCode = this.commonService.getBlogPageCategoryCode('CookingSchool') || '';
      const fetchQuery = { include: 10, limit: 10, 'fields.pageCategory.sys.id': pageCode };
      this.cookingClasses = (await firstValueFrom(this.cms.getContent('fdinRecipeCard', fetchQuery))) as IFdinRecipeCard[];
      this.showCookingClasses = true;
      this.titleService.setTitle(this.setTitle);
    } else {
      this.showCookingClasses = false;
      this.titleService.setTitle(`cooking classes | ${slug}`);
    }

    this.blogPost = (await firstValueFrom(this.cms.getContent('fdinBlogPost', { 'fields.slug': `cooking-school/${slug}` })))[0] as IFdinBlogPost;
    // if we couldn't find a matching post, send them to the 404 page
    if (!this.blogPost) {
      this.router.navigateByUrl('/pages/not-found');
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}
