import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IFdinCard } from '../../interfaces/contentful';

@Component({
  selector: 'app-card-layout',
  templateUrl: './card-layout.component.html',
  styleUrls: ['./card-layout.component.scss']
})
export class CardLayoutComponent implements OnInit {
  @Input() cardData?: IFdinCard;

  @Input() btnLabel?: string;

  @Input() ShowButton: boolean = false;

  safeIframeSrc?: SafeResourceUrl;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    if (this.cardData?.fields?.iframeUrl) {
      this.safeIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.cardData?.fields?.iframeUrl);
    }
  }

  safeIframeSrcConverter() {
    if (this.cardData?.fields?.iframeUrl) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.cardData?.fields?.iframeUrl);
    }
    return null;
  }

  navigateToURL(url: any) {
    this.router.navigateByUrl(url);
  }
}
