import {
  Directive, HostBinding, HostListener, Input 
} from '@angular/core';
import { Router } from '@angular/router';
import { IFdinLink } from '../interfaces/contentful';

@Directive({
  selector: '[appHandleCMSLink]',
  // host: {
  //   '[style.cursor]': '"pointer"',
  // }
})

/**
 * Handles links from the CMS. If the link is marked as external, opens the link in a new window.
 * If the link is not external, it just navigates via the router.
 */
export class HandleCMSLinkDirective {
  @HostBinding('style.cursor')
    pointer = '"pointer"';

  @Input() appHandleCMSLink?: IFdinLink; // the link object from the cms content

  constructor(private router: Router) {

  }

  @HostListener('click') onClick() {
    if (!this.appHandleCMSLink?.fields?.url) {
      return;
    }

    if (this.appHandleCMSLink.fields.isExternal) {
      window.open(this.appHandleCMSLink.fields.url, '_blank');
    } else {
      this.router.navigateByUrl(this.appHandleCMSLink.fields.url);
    }
  }
}
