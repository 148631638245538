import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { IFdinRecipeCard, IFdinRecipePage } from 'src/app/shared/interfaces/contentful';
import { CommonService } from 'src/app/shared/services/common.service';
import { ContentfulService } from 'src/app/shared/services/contentful.service';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-cooking-classes',
  templateUrl: './cooking-classes.component.html',
  styleUrls: ['./cooking-classes.component.scss']
})
export class CookingClassesComponent implements OnDestroy {
  cookingClassContent?: IFdinRecipePage;

  safeIframeSrc?: SafeResourceUrl;

  cookingClasses: IFdinRecipeCard[] = [];

  private componentDestroyed = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private cms: ContentfulService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
  ) {
    this.watchForRouteChange();
    register();
  }

  /**
   * If the user clicks a different blog link, the route will change but since the target component is the same, the page won't reload on its own.
   */
  watchForRouteChange() {
    this.route.paramMap
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.getContent();
      });
  }

  navigateToURL(url: any) {
    this.router.navigateByUrl(url);
  }

  /**
   * Return nav contents.
   *
   */
  async getContent() {
    // first get the page slug
    const slug = this.route.snapshot.paramMap.get('class-name');
    const pageCode = this.commonService.getBlogPageCategoryCode('CookingSchool') || '';

    this.cookingClassContent = (await firstValueFrom(this.cms.getContent('fdinRecipePage', {
      include: 10,
      'fields.slug': slug
    })))[0] as IFdinRecipePage;

    this.cookingClasses = (await firstValueFrom(this.cms.getContent('fdinRecipeCard', { 'fields.pageCategory.sys.id': pageCode }))) as IFdinRecipeCard[];

    if (this.cookingClassContent?.fields?.iframeUrl) {
      this.safeIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.cookingClassContent?.fields?.iframeUrl);
    }

    // if we couldn't find a matching post, send them to the 404 page
    if (!this.cookingClassContent) {
      this.router.navigateByUrl('/pages/not-found');
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}
