import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RenderRichTextDirective } from './directives/render-rich-text.directive';
import { CardLayoutComponent } from './components/card-layout/card-layout.component';
import { ContentPostComponent } from './components/content-post/content-post.component';
import { SearchViewComponent } from './components/search-view/search-view.component';
import { BlogPostPageComponent } from './components/blog-post-page/blog-post-page.component';
import { CalendlyWidgetComponent } from './components/calendly-widget/calendly-widget.component';
import { MailingListComponent } from './components/mailing-list/mailing-list.component';
import { CardWrapperComponent } from './components/card-wrapper/card-wrapper.component';

@NgModule({
  declarations: [
    RenderRichTextDirective,
    CardLayoutComponent,
    ContentPostComponent,
    SearchViewComponent,
    BlogPostPageComponent,
    CalendlyWidgetComponent,
    MailingListComponent,
    CardWrapperComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FontAwesomeModule
  ],
  exports: [
    RenderRichTextDirective,
    CardLayoutComponent,
    ContentPostComponent,
    SearchViewComponent,
    BlogPostPageComponent,
    CalendlyWidgetComponent,
    MailingListComponent,
    CardWrapperComponent
  ]
})
export class SharedModule {
}
