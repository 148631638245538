import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ContentfulService } from '../shared/services/contentful.service';
import { IFdinRecipeCard } from '../shared/interfaces/contentful';
import { TitleService } from '../shared/services/title.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnDestroy {
  public searchLoading: boolean = true;

  recipeContent?: IFdinRecipeCard[];

  private componentDestroyed = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
     private cms: ContentfulService,
     private titleService: TitleService

  ) {
    this.titleService.setTitle('Search results');

    this.watchForRouteChange();
  }

  /**
   * If the user searches for something else, the route will change but since the target component is the same, the page won't refresh.
   * So we need to watch for changes to the route.
   */
  watchForRouteChange() {
    this.route.paramMap
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.getSearchResults();
      });
  }

  async getSearchResults() {
    // get the query string from the route
    const encodedSearchString = this.route.snapshot.paramMap.get('search-string');

    if (!encodedSearchString) {
      this.searchLoading = false;
      return;
    }

    const searchString = decodeURIComponent(encodedSearchString);
    this.recipeContent = (await firstValueFrom(this.cms.getContent('fdinRecipeCard', {
      include: 10,
      query: searchString,
      limit: 10
    }))) as IFdinRecipeCard[];

    this.searchLoading = false;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}
