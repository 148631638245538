<section class="search">
  <div class="searchContent">
    <div class="heading">
      <h4>TRY THESE AT HOME</h4>
      <h2>Our {{allSelectedLabel}}</h2>
      <h4>&nbsp;</h4>
    </div>

    <section class="searchWrapper">
      <label for="search">Search</label>
      <input [(ngModel)]="searchString" name="search" placeholder="Search..." type="text">
      <button (click)="search()" aria-label="search" class="searchBtn" type="submit">
        <fa-icon [icon]="['fas', 'search']" aria-hidden="true"></fa-icon>
      </button>
    </section>
    <div [ngClass]="{'container': showSideNav}">
      <ng-container *ngIf="showSideNav">
        <div class="mainFacet filterBox">
          <label class="parentList">
            <input (change)="selectAll(); allSelected = true" [(ngModel)]="allSelected" class="facet-value-checkbox"
                   id="aemTagCheck"
                   type="checkbox">
            <span class="text">{{allSelectedLabel}}</span>
          </label>
          <ul class="childList facet-items-aem facet-items">
            <li *ngFor="let item of categoryList; let i=index">
              <label for="{{item.value}}">
                <input (change)="search();" [(ngModel)]="item.isSelected" class="facet-value-checkbox" name="aemTag"
                       type="checkbox">
                <span class="text">{{item.value}}</span>
              </label>
            </li>
          </ul>
        </div>
      </ng-container>
      <div class="searchResult box">
        <section *ngIf="searchResults.length > 0 ; else  noResult ">
          <div class="alert" id="search-statistics"><span><b>Showing all pages</b></span></div>
          <!-- <div class="cardContainer">
            <div class="child" *ngFor="let cardCollection of searchResults ">
              <app-card-layout class="cardBtn" [cardData]="cardCollection?.fields?.recipe"
                [btnLabel]="btnLabel"></app-card-layout>
            </div>
          </div> -->
          <div>
            <app-card-wrapper [ShowSearchResults]="true" [parentBackground]="true" [searchResults]="searchResults"
            [btnLabel]="btnLabel"   [showBlogPostContent]="false"></app-card-wrapper>
          </div>
        </section>


      </div>
    </div>
  </div>
</section>
<ng-template #noResult>
  <div class="noResult">
    <div class="alert" id="search-statistics"><span><b>No Search Result Found</b></span></div>
  </div>
</ng-template>
