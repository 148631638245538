import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { IFdinRecipeCard, IFdinRecipePage } from 'src/app/shared/interfaces/contentful';
import { ContentfulService } from 'src/app/shared/services/contentful.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-recipe-page',
  templateUrl: './recipe-page.component.html',
  styleUrls: ['./recipe-page.component.scss']
})
export class RecipePageComponent implements OnDestroy {
  recipePageContent?: IFdinRecipePage;

  recipeCardContent?: IFdinRecipeCard[];

  pageUrl: string = '';

  private componentDestroyed = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private cms: ContentfulService,
    private router: Router,
    private titleService: TitleService
  ) {
    this.pageUrl = window.location.href;
    this.watchForRouteChange();
    register();
  }

  /**
   * If the user clicks a different blog link, the route will change but since the target component is the same, the page won't reload on its own.
   */
  watchForRouteChange() {
    this.route.paramMap
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.getContent();
      });
  }

  /**
   * Return nav contents.
   *
   */
  async getContent() {
    // first get the page slug
    const slug = this.route.snapshot.paramMap.get('recipe-name');
    this.titleService.setTitle(`Recipe | ${slug}`);
    this.recipePageContent = (await firstValueFrom(this.cms.getContent('fdinRecipePage', {
      include: 10,
      'fields.slug': slug
    })))[0] as IFdinRecipePage;

    this.recipeCardContent = (await firstValueFrom(this.cms.getContent('fdinRecipeCard', {
      include: 10,
      limit: 10
    }))) as IFdinRecipeCard[];

    // if we couldn't find a matching post, send them to the 404 page
    if (!this.recipePageContent) {
      this.router.navigateByUrl('/pages/not-found');
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}
