<section class="footerSession contentCard">
  <div class="container">
    <app-card-wrapper [ShowSearchResults]="false" [blogPostContent]="footerContent?.fields?.content!"
                      [parentBackground]="true" [showBlogPostContent]="true"></app-card-wrapper>

  </div>
</section>
<section class="footerSession">
  <div>
    <h4>WE OFFER A LOT</h4>
    <h2>Explore More</h2>

  </div>
  <div class="container">
    <!-- <div class="cardWrapContainer" *ngFor="let footerTile of footerContent?.fields?.footerTiles">
      <app-card-layout [cardData]="footerTile"> </app-card-layout>

    </div> -->
    <app-card-wrapper [ShowSearchResults]="false" [blogPostContent]="footerContent?.fields?.footerTiles!"
                      [showBlogPostContent]="true" [wrapContent]="false"></app-card-wrapper>

  </div>
</section>
<section class="footerSession">
  <div class="container">
    <app-stay-connected [icon]="'facebook-icon.svg'" [textLink]="'https://www.facebook.com/foodinnovators/'"
                        [title]="'Facebook'"
                        class="fourCard"></app-stay-connected>
    <app-stay-connected [icon]="'instagram-icon.svg'" [textLink]="'https://www.instagram.com/innovatorsfood/'"
                        [title]="'Instagram'"
                        class="fourCard"></app-stay-connected>
    <ng-container *ngIf="showContent()">
      <app-stay-connected [icon]="'currents.png'"
                          [textLink]="'https://plus.google.com/u/0/communities/108708208550789694369'"
                          [title]="'Currents'"
                          class="fourCard"></app-stay-connected>
      <app-stay-connected [icon]="'youtube-icon.png'"
                          [textLink]="'https://www.youtube.com/channel/UCBDNKcN9E3rV5KoC0_yEEXQ/featured'"
                          [title]="'YouTube'"
                          class="fourCard"></app-stay-connected>
    </ng-container>
  </div>
</section>
<app-mailing-list></app-mailing-list>
