import { Injectable } from '@angular/core';
import { Observable, tap, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.innovatorsFoodBackendUrl;

  private readonly GOOGLE_TOKEN_ENDPOINT = 'https://oauth2.googleapis.com/token';

  private readonly CLIENT_ID = 'your-client-id';

  private readonly CLIENT_SECRET = 'your-client-secret';

  private readonly REDIRECT_URI = 'https://your-redirect-uri.com';

  private readonly tokenName = 'googleSsoToken';

  constructor(private http: HttpClient) {
  }

  public verifyIAPAssociation(idToken: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);

    const url = `${this.baseUrl}/auth`;
    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        console.error('HTTP Request Error:', error);

        if (error.error && error.error.message) {
          console.error('Server Error Message:', error.error.message);
        }

        return throwError(() => new Error('An error occurred while verifying IAP association.'));
      })
    );
  }

  verifyAndExchangeOAuthTokens(idToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = `client_id=${this.CLIENT_ID}&`
      + `client_secret=${this.CLIENT_SECRET}&`
      + `code=${idToken}&`
      + `redirect_uri=${this.REDIRECT_URI}&`
      + 'grant_type=authorization_code';

    return this.http.post(this.GOOGLE_TOKEN_ENDPOINT, body, { headers }).pipe(
      catchError((error) => {
        // Handle error, e.g., invalid ID token, network error, etc.
        throw error;
      })
    );
  }

  public isLoggedIn(): boolean {
    return !!localStorage.getItem(this.tokenName);
  }

  public storeToken(token: string): void {
    localStorage.setItem(this.tokenName, token);
  }

  public deleteToken(): void {
    localStorage.removeItem(this.tokenName);
  }

  public getAccessToken(): string | null {
    return localStorage.getItem(this.tokenName);
  }

  refreshAccessToken(): Observable<any> {
    const refreshToken = this.getAccessToken();
    if (!refreshToken) {
      return throwError(() => new Error('No refresh token available'));
    }

    const refreshTokenPayload = { refresh_token: refreshToken };

    return this.http.post<any>('your-token-refresh-endpoint', refreshTokenPayload).pipe(
      tap((response) => {
        if (response.access_token) {
          this.storeToken(response.access_token);
        }
      }),
      catchError((error) => {
        this.deleteToken();
        return throwError(() => error);
      })
    );
  }
}
