import { Component, Input } from '@angular/core';
import { IFdinBlogPost } from '../../interfaces/contentful';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-blog-post-page',
  templateUrl: './blog-post-page.component.html',
  styleUrls: ['./blog-post-page.component.scss']
})
export class BlogPostPageComponent {
  @Input() blogPostContent?: IFdinBlogPost;

  constructor(
    private authService: AuthService,
  ) {
  }

  showContent(authenticationRequred: boolean = false): boolean {
    if (authenticationRequred) {
      return this.authService.isLoggedIn();
    }
    return true;
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }
}
