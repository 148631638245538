import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IFdinMediaAndText } from '../../interfaces/contentful';

@Component({
  selector: 'app-content-post',
  templateUrl: './content-post.component.html',
  styleUrls: ['./content-post.component.scss']
})
export class ContentPostComponent implements OnInit {
  @Input() contentPost?: IFdinMediaAndText;

  safeIframeSrc?: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    if (this.contentPost?.fields?.iframeUrl) {
      this.safeIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.contentPost?.fields?.iframeUrl);
    }
  }
}
