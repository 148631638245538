<ng-container *ngIf="blogPost">
  <app-blog-post-page [blogPostContent]="blogPost"></app-blog-post-page>
</ng-container>


<ng-container *ngIf="showStoriesTrendsHome">
  <section class="swiperContainer">
    <div class="title">
      <h4>FEATURED</h4>
      <h2>Food Stories</h2>
    </div>
    <swiper-container [navigation]="{}"
                      [pagination]="{ clickable: true }"
                      [scrollbar]="{ draggable: true }"
                      [slidesPerView]="3"
                      [spaceBetween]="50"
                      [watchSlidesProgress]="true">
      <swiper-slide *ngFor="let cardCollection of StoriesTrendsContent" class="cardWrapContainer">
        <app-card-layout [btnLabel]="'Read More'" [cardData]="cardCollection?.fields?.recipe"
                         class="swiperChild"></app-card-layout>
      </swiper-slide>
    </swiper-container>
  </section>
</ng-container>
<app-search-view [btnLabel]="'Read More'" [calledFrom]="'StoriesAndTrends'" [categoryName]="categoryName"
                 [showSideNav]="showSideNav"></app-search-view>
