import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faCircle, faComment, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faBars,
  faChevronRight,
  faGlobeAsia,
  faLock,
  faPhone,
  faSearch,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { SharedModule } from './shared/shared.module';
import { NavComponent } from './nav/components/nav.component';
import { HandleCMSLinkDirective } from './shared/directives/handle-cms-link.directive';
import { FooterModule } from './footer/footer.module';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RecipesModule } from './recipes/recipes.module';
import { SearchResultsComponent } from './search-results/search-results.component';
import { OurKitchensComponent } from './our-kitchens/our-kitchens.component';
import { ResourcesComponent } from './resources/resources.component';
import { StoriesTrendsModule } from './stories-trends/stories-trends.module';
import { CookingSchoolModule } from './cooking-school/cooking-school.module';
import { AuthorControlsComponent } from './shared/components/author-controls/author-controls.component';
export const WINDOW = new InjectionToken('WINDOW');

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HandleCMSLinkDirective,
    WhatWeDoComponent,
    PageNotFoundComponent,
    SearchResultsComponent,
    OurKitchensComponent,
    ResourcesComponent,
    AuthorControlsComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    FooterModule,
    FontAwesomeModule,
    RecipesModule,
    StoriesTrendsModule,
    CookingSchoolModule,
  ],
  providers: [
    {
      provide: WINDOW,
      useValue: window,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faChevronRight, faBars, faTimes, faSearch, faCircle, faAngleRight, faAngleLeft, faGlobeAsia, faEnvelope, faLock, faComment, faPhone, faLinkedin);
  }
}
