import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IFdinBlogPost, IFdinFooter, IFdinRecipeCard } from 'src/app/shared/interfaces/contentful';
import { ContentfulService } from 'src/app/shared/services/contentful.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss']
})
export class RecipesComponent implements OnInit {
  recipeCardContent?: IFdinRecipeCard[];

  blogPost!: IFdinBlogPost;

  recipeAdvancedSection?: IFdinFooter;

  constructor(
    private cms: ContentfulService,
    private titleService: TitleService
  ) {
    register();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Recipes | home');
    this.getContent();
  }

  /**
   * Return recipe contents.
   *
   */
  async getContent() {
    this.blogPost = (await firstValueFrom(this.cms.getContent('fdinBlogPost', { 'fields.slug': 'recipes' })))[0] as IFdinBlogPost;
    this.recipeCardContent = (await firstValueFrom(this.cms.getContent('fdinRecipeCard', {
      include: 10,
      limit: 10,
      'fields.pageCategory.sys.id': '5U4NEVxyJrf3RYZj96J1YS'
    }))) as IFdinRecipeCard[];
    this.recipeAdvancedSection = (await firstValueFrom(this.cms.getContent('fdinFooter', { include: 10, 'fields.slug': 'recipes' })))[0] as IFdinFooter;
  }
}
