<app-blog-post-page [blogPostContent]="blogPost"></app-blog-post-page>
<section *ngIf="showCookingClasses && cookingClasses">
  <swiper-container [navigation]="{}"
                    [pagination]="{ clickable: true }"
                    [scrollbar]="{ draggable: true }"
                    [slidesPerView]="3"
                    [spaceBetween]="50"
                    [watchSlidesProgress]="true">
    <swiper-slide *ngFor="let cookingClasses of cookingClasses" class="cardWrapContainer">
      <app-card-layout [cardData]="cookingClasses?.fields?.recipe" class="swiperChild"></app-card-layout>
    </swiper-slide>

  </swiper-container>
</section>
<section *ngIf="showCookingClasses">
  <app-search-view [btnLabel]="'View Here'" [calledFrom]="'CookingSchool'"
                   [categoryName]="'Cooking_Classes'"></app-search-view>
</section>
