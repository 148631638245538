import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IFdinMainNavigation } from 'src/app/shared/interfaces/contentful';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NavService } from '../nav.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  public navItems?: IFdinMainNavigation;

  public searchString: string = '';

  public isSlideOutNavOpen: boolean = false;

  showNavDropdown: boolean = true;

  isUserLoggedIn:boolean = false;

  userData:any = {};

  constructor(
    private _navService: NavService,
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.getNavContent();
  }

  ngOnInit() {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: this.ssoCallback.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });
    google.accounts.id.renderButton(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.getElementById('google-button')!,
      {
        type: 'standard',
        theme: 'filled_blue',
        size: 'medium',
        text: 'continue_with',
        shape: 'pill'
      }
    );
    this.isLoggedIn();
    this.getAccessTokenData();
  }

  decodeJwtResponse(token:any) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
  }

  isLoggedIn(): void {
    this.isUserLoggedIn = this.authService.isLoggedIn();
  }

  getAccessTokenData() {
    const AccessToken = this.authService.getAccessToken();
    if (AccessToken) {
      this.userData = this.decodeJwtResponse(AccessToken);
    }
  }

  showContent(authenticationRequired: boolean = false): boolean {
    if (authenticationRequired) {
      return this.authService.isLoggedIn();
    }
    return true;
  }

  async logout(): Promise<void> {
    this.authService.deleteToken();
    this.userData = {};
    this.isLoggedIn();
  }

  async ssoCallback(response: any): Promise<void> {
    if (response.credential) {
      const idToken = response.credential;
      this.authService.storeToken(idToken);
      this.isLoggedIn();
      this.userData = this.decodeJwtResponse(idToken);

      // this.ngZone.run(() => {
      //   //this.router.navigate(['/admin']);
      // });
      // this.authService.verifyIAPAssociation(idToken).subscribe({
      //   next: async () => {
      //     this.authService.storeToken(idToken);
      //     this.ngZone.run(() => {
      //       this.router.navigate(['/admin']);
      //     });
      //   },
      //   error: async (error) => {
      //     this.ngZone.run(() => {
      //       this.showError = true;
      //     });
      //   }
      // });
    } else {
      this.ngZone.run(() => {
        console.log('credential else');
        // this.router.navigate(['/']);
      });
    }
  }

  /**
   * Get nav items from the CMS.
   */
  async getNavContent() {
    this.navItems = await this._navService.getNavContent();
  }

  public search() {
    if (this.searchString) {
      this.router.navigateByUrl(`/search/${encodeURIComponent(this.searchString)}`);
    }
  }
}
