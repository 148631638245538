import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/components/home/home.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RecipesComponent } from './recipes/components/home/recipes.component';
import { RecipePageComponent } from './recipes/components/recipe-page/recipe-page.component';
import { RecipesSearchComponent } from './recipes/components/recipes-search/recipes-search.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { OurKitchensComponent } from './our-kitchens/our-kitchens.component';
import { ResourcesComponent } from './resources/resources.component';
import { StoriesTrendsComponent } from './stories-trends/components/stories-trends/stories-trends.component';
import { CookingSchoolComponent } from './cooking-school/components/cooking-school/cooking-school.component';
import { CookingClassesComponent } from './cooking-school/components/cooking-classes/cooking-classes.component';
import {
  StoriesTrendsBlogComponent
} from './stories-trends/components/stories-trends-blog/stories-trends-blog.component';
import { NotFoundDynamicRedirectGuardGuard } from './shared/guard/not-found-dynamic-redirect-guard.guard';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'what-we-do/:category-name', component: WhatWeDoComponent },
  { path: 'cooking-school/:category-name', component: CookingSchoolComponent },
  { path: 'cooking-school/cooking-classes/:class-name', component: CookingClassesComponent },
  // Refactor needed
  { path: 'recipes', component: RecipesComponent },
  { path: 'recipes/:category-name', component: RecipesSearchComponent },
  { path: 'recipes/blog/:recipe-name', component: RecipePageComponent },

  { path: 'ourKitchens', component: OurKitchensComponent },

  { path: 'resources/home', component: ResourcesComponent },

  { path: 'blog/:category-name', component: StoriesTrendsComponent },
  { path: 'blog/:category-name/:blog-name', component: StoriesTrendsBlogComponent },
  { path: 'search/:search-string', component: SearchResultsComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },

  {
    path: '**',
    canActivate: [NotFoundDynamicRedirectGuardGuard],
    children: []
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
