<app-blog-post-page [blogPostContent]="blogPost"></app-blog-post-page>

<section class="swiperContainer">
  <div class="title">
    <h4>GET COOKING</h4>
    <h2>More Recipes</h2>
  </div>
  <swiper-container [navigation]="{}"
                    [pagination]="{ clickable: true }"
                    [scrollbar]="{ draggable: true }"
                    [slidesPerView]="3"
                    [spaceBetween]="50"
                    [watchSlidesProgress]="true">
    <swiper-slide *ngFor="let cardCollection of recipeCardContent" class="cardWrapContainer">
      <app-card-layout [btnLabel]="'Get Cooking'" [cardData]="cardCollection?.fields?.recipe"
                       class="swiperChild"></app-card-layout>
    </swiper-slide>
  </swiper-container>
</section>

<section class="broeseByTypeYPEContainer">

  <div class="title">
    <h4>BROWSE BY TYPE</h4>
    <h2>Meal</h2>
  </div>
  <div class="container">
    <!-- <div class="cardWrapContainer" *ngFor="let footerTile of footerContent?.fields?.footerTiles">
      <app-card-layout [cardData]="footerTile"> </app-card-layout>

    </div> -->
    <app-card-wrapper [ShowSearchResults]="false" [blogPostContent]="recipeAdvancedSection?.fields?.footerTiles!"
                      [showBlogPostContent]="true" [wrapContent]="false"></app-card-wrapper>

  </div>
  <div>

  </div>
</section>

<section class="swiperContainer">
  <div class="title">
    <h4>BROWSE BY TYPE</h4>
    <h2>Food Innovator Favourites</h2>
  </div>
  <swiper-container [navigation]="{}"
                    [pagination]="{ clickable: true }"
                    [scrollbar]="{ draggable: true }"
                    [slidesPerView]="3"
                    [spaceBetween]="50"
                    [watchSlidesProgress]="true">
    <swiper-slide *ngFor="let cardCollection of recipeCardContent" class="cardWrapContainer">
      <app-card-layout [btnLabel]="'Get Cooking'" [cardData]="cardCollection?.fields?.recipe"
                       class="swiperChild"></app-card-layout>
    </swiper-slide>
  </swiper-container>
</section>
