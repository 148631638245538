import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  categoryListCodeMap = new Map([
    ['baking', 'y69iQzWZihtiMxSp0zwV8'],
    ['desserts', '5eEbNJHaWD2IPMVC5kgyvx'],
    ['dinner', '1d6Ne9u8WziEemuWIbTG9y'],
    ['lunch', '2mdbZ2bbIoXeL5yUiZ6XAr'],
    ['salads', '1ViFJrh8a9avtXxxuYhGGj'],
    ['snacks', '23UFsVpD0i4XvXgko2mfra'],
    ['breakfast', '5ZKM7nZpOaDxq6O0LaT1UP'],
    ['cooking-classes', '68ZFlxfjKwxlwyHxlQ62ej'],
    ['pastClasses', '6Rh2jwxUMB0jGgUEzFNlGz'],
    ['stories', 'pPY6daL60KsuwlhXjzs5c'],
    ['trends', '6LO0g90k1Gr6XwtKyNEDUe'],
    ['travel', '5fK8siY8ifcnPks03RJVFE'],
    ['ingredients', '63PQCI3w3VnnJgMsjCBl6z'],
  ]);

  blogPageCategoryCodeMap = new Map([
    ['StoriesAndTrends', '5CcLiYe0lHg77LoLFELyPx'],
    ['Recipes', '5U4NEVxyJrf3RYZj96J1YS'],
    ['Food-Trends', '19YnCZK4TqLXNBkecBHw3A'],
    ['CookingSchool', '6hv17Q8VhgRpCemDANgBaO'],
    ['Food_Stories', '6lZ9prpSbvdl5zOT8zcCBI'],
  ]);

  constructor() {
  }

  public getCategoryListCode(name: string) {
    return this.categoryListCodeMap.get(name.trim());
  }

  public getBlogPageCategoryCode(name: string) {
    return this.blogPageCategoryCodeMap.get(name.trim());
  }
}
