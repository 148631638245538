import { Injectable } from '@angular/core';
import { ContentfulService } from '../shared/services/contentful.service';
import { IFdinMainNavigation } from '../shared/interfaces/contentful';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  constructor(private cms: ContentfulService) {
  }

  /**
   * Return nav contents.
   */
  async getNavContent() {
    return (await firstValueFrom(this.cms.getContent('fdinMainNavigation', { include: 10 })))[0] as IFdinMainNavigation;
  }
}
