import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoriesTrendsBlogComponent } from './components/stories-trends-blog/stories-trends-blog.component';
import { StoriesTrendsComponent } from './components/stories-trends/stories-trends.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    StoriesTrendsBlogComponent,
    StoriesTrendsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StoriesTrendsModule {
}
