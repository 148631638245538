<div class="stayConnected">
  <div class="textCenter">
    <h6>
      <a [href]="textLink" target="_blank">STAY CONNECTED ON</a>
    </h6>
    <h2>
      <a [href]="textLink" target="_blank">{{title}}</a>
    </h2>
    <p>&nbsp;</p>
  </div>
</div>
<div class="stayConnected">
  <a [href]="textLink" target="_blank"> <img [src]="iconPath" alt="youtube" class="icon"> </a>
</div>
