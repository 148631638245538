import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { IFdinRecipeCard } from 'src/app/shared/interfaces/contentful';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-recipes-search',
  templateUrl: './recipes-search.component.html',
  styleUrls: ['./recipes-search.component.scss']
})
export class RecipesSearchComponent implements OnDestroy {
  recipeContent?: IFdinRecipeCard[];

  fetchQuery: object = [];

  categoryName: string = '';

  private componentDestroyed = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService

  ) {
    this.watchForRouteChange();
  }

  /**
   * If the user clicks a different blog link, the route will change but since the target component is the same, the page won't reload on its own.
   */
  watchForRouteChange() {
    this.route.paramMap
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((param) => {
        this.categoryName = param.get('category-name') || '';
        this.titleService.setTitle(`Recipe Search ${this.categoryName}`);
      });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}
