import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecipesRoutingModule } from './recipes-routing.module';
import { RecipesComponent } from './components/home/recipes.component';
import { RecipePageComponent } from './components/recipe-page/recipe-page.component';
import { SharedModule } from '../shared/shared.module';
import { RecipesSearchComponent } from './components/recipes-search/recipes-search.component';

@NgModule({
  declarations: [
    RecipesComponent,
    RecipePageComponent,
    RecipesSearchComponent
  ],
  imports: [
    CommonModule,
    RecipesRoutingModule,
    SharedModule,
  ],
  exports: [RecipesRoutingModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class RecipesModule {
}
