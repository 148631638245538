import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { IFdinRecipeCard, IFdinRecipePage } from 'src/app/shared/interfaces/contentful';
import { ContentfulService } from 'src/app/shared/services/contentful.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { register } from 'swiper/element';

@Component({
  selector: 'app-stories-trends-blog',
  templateUrl: './stories-trends-blog.component.html',
  styleUrls: ['./stories-trends-blog.component.scss']
})
export class StoriesTrendsBlogComponent implements OnDestroy {
  StoriesTrendsContent?: IFdinRecipeCard[] = [];

  blogPageContent?: IFdinRecipePage;

  private componentDestroyed = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private cms: ContentfulService,
    private titleService: TitleService

  ) {
    this.watchForRouteChange();
    register();
  }

  /**
   * If the user clicks a different blog link, the route will change but since the target component is the same, the page won't reload on its own.
   */
  watchForRouteChange() {
    this.route.paramMap
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.getContent();
      });
  }

  /**
   * Return nav contents.
   *
   */
  async getContent() {
    // first get the page slug
    const category = this.route.snapshot.paramMap.get('category-name');
    const blog = this.route.snapshot.paramMap.get('blog-name');
    this.titleService.setTitle(`Stories trends | ${category} | ${blog}`);

    this.blogPageContent = (await firstValueFrom(this.cms.getContent('fdinRecipePage', {
      include: 10,
      'fields.slug': `blog/${category}/${blog}`
    })))[0] as IFdinRecipePage;
    this.StoriesTrendsContent = (await firstValueFrom(this.cms.getContent('fdinRecipeCard', {
      include: 10,
      limit: 10
    }))) as IFdinRecipeCard[];
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}
