import { Component } from '@angular/core';
import { IFdinHomePage, IFdinRecipeCard } from 'src/app/shared/interfaces/contentful';
import { register } from 'swiper/element/bundle';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HomeService } from '../../home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  homePageContent?: IFdinHomePage;

  cookingClasses: IFdinRecipeCard[] = [];

  constructor(
    private _homeService: HomeService,
    private commonService: CommonService,
    private authService: AuthService,
  ) {
    this.getNavContent();
    this.getCookingClass();
    register();
  }

  /**
   * Get nav items from the CMS.
   */
  async getNavContent() {
    this.homePageContent = await this._homeService.getNavContent();
  }

  showContent(authenticationRequred: boolean = false): boolean {
    if (authenticationRequred) {
      return this.authService.isLoggedIn();
    }
    return true;
  }

  async getCookingClass() {
    const pageCode = this.commonService.getBlogPageCategoryCode('CookingSchool') || '';
    const fetchQuery: object = { include: 10, 'fields.pageCategory.sys.id': pageCode };
    this.cookingClasses = (await this._homeService.getCookingClass(fetchQuery)) as IFdinRecipeCard[];
  }
}
