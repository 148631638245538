<div>
  <div *ngIf="blogPostContent?.fields?.heading1 || blogPostContent?.fields?.heading2" class="heading">
    <h4 *ngIf="blogPostContent?.fields?.heading1"> {{blogPostContent?.fields?.heading1}}</h4>
    <h1 *ngIf="blogPostContent?.fields?.heading2">{{blogPostContent?.fields?.heading2}}</h1>
    <p *ngIf="blogPostContent?.fields?.heading3">{{blogPostContent?.fields?.heading3}}</p>
  </div>


  <div *ngIf="blogPostContent?.fields?.textOverImage?.fields?.media?.fields?.file?.url "
       [style.background-image]="'url(' + blogPostContent?.fields?.textOverImage?.fields?.media?.fields?.file?.url + ')'"
       class="heroImage">

    <div class="heroTitle">
      <div [appRenderRichText]="blogPostContent?.fields?.textOverImage?.fields?.text" class="content">

      </div>
    </div>
  </div>

  <div class="cardContainer">
    <app-card-wrapper [ShowSearchResults]="false" [blogPostContent]="blogPostContent?.fields?.cardCollection!"
                      [showBlogPostContent]="true"></app-card-wrapper>

    <!-- <div class="cardWrapContainer" *ngFor="let cardCollection of blogPostContent?.fields?.cardCollection ">
      <app-card-layout [cardData]="cardCollection"></app-card-layout>
    </div> -->
  </div>

  <div *ngFor="let contentPost of blogPostContent?.fields?.content " class="contentPostParent">
    <app-content-post [ngClass]="contentPost.fields.contentPostPosition" *ngIf=" showContent(contentPost?.fields?.authenticationRequired)" [contentPost]="contentPost">
    </app-content-post>
  </div>
  <ng-container *ngIf="showContent(blogPostContent?.fields?.imageAndText?.fields?.hideForAuthenticated)">
    <div *ngIf="blogPostContent?.fields?.imageAndText?.fields?.media?.fields?.file?.url "
         [style.background-image]="'url(' + blogPostContent?.fields?.imageAndText?.fields?.media?.fields?.file?.url + ')'"
         class="XLImage">
      <div class="XLImageContent">
        <div [appRenderRichText]="blogPostContent?.fields?.imageAndText?.fields?.text" class="content">

        </div>
      </div>
    </div>
  </ng-container>

  <section *ngIf="blogPostContent?.fields?.textOverLapCardsTittle && blogPostContent?.fields?.textOverLapCards">


    <div class="heading">
      <h2>{{blogPostContent?.fields?.textOverLapCardsTittle}}</h2>
    </div>
    <div class="textOverTiles">
      <app-card-wrapper [ShowSearchResults]="false" [blogPostContent]="blogPostContent?.fields?.textOverLapCards!"
                        [showBlogPostContent]="true" [textOverTiles]='true'></app-card-wrapper>

      <div>


      </div>
    </div>
  </section>
</div>
<div [style.background-image]="'url(assets/image/cream-bg.png)'" class="threeColumCardContainer">
  <div *ngFor="let cardCollection of blogPostContent?.fields?.threeColumnLayout "
       class="threeColumnLayoutCardContainer">
    <app-card-layout [cardData]="cardCollection"></app-card-layout>
  </div>
  <!-- <app-card-wrapper [blogPostContent]="blogPostContent?.fields?.threeColumnLayout!" [ShowSearchResults]="false" [showBlogPostContent]="true"></app-card-wrapper> -->

</div>
