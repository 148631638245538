import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CookingSchoolRoutingModule } from './cooking-school-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CookingClassesComponent } from './components/cooking-classes/cooking-classes.component';
import { CookingSchoolComponent } from './components/cooking-school/cooking-school.component';

@NgModule({
  declarations: [
    CookingSchoolComponent,
    CookingClassesComponent
  ],
  imports: [
    CommonModule,
    CookingSchoolRoutingModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class CookingSchoolModule {
}
