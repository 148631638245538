<section class="footerSession emailContainer">
  <div>
    <h2>Signup here for Updates from us!</h2>
  </div>
  <div>
    <input class="emailInput" email name="email" ngModel type="email">
  </div>
</section>
<section class="footerSession">
  <div>
    <img alt="Food Innovators logo" class="logo" src="assets/icon/food-innovators-logo.svg">
    <h3><a href="/content/innovators-food/en/home/what-we-do/contact-us">Contact Us</a></h3>
  </div>
  <div class="centerContainer">
    <div>
      <p>© Woolworths Ltd. 2019. All rights reserved. ABN 88 000 014 675&nbsp;</p>
      <div>
        Icons made by
        <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from
        <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is licensed by
        <a href="http://creativecommons.org/licenses/by/3.0/" target="_blank" title="Creative Commons BY 3.0">CC 3.0
          BY</a>
      </div>

    </div>
  </div>
</section>
