<section>
  <div class='cookingClassWrapper'>
    <div class='cookingClassRow'>
      <div class='cookingClassColumn'>
        <div class="cardInfo">
          <div class="dateInfo">
            <b> {{cookingClassContent?.fields?.publishingDate}}</b>
          </div>
          <h1 class="title"> {{cookingClassContent?.fields?.heading}}</h1>
        </div>
        <div [appRenderRichText]="cookingClassContent?.fields?.introduction" class="description"></div>
        <div [appRenderRichText]="cookingClassContent?.fields?.leftContent" class="servingInfo"></div>
        <div class="btnBookNow">
          <a [href]="cookingClassContent?.fields?.link?.fields?.url" class="btn btn-outline-success"
             target="_blank">{{cookingClassContent?.fields?.link?.fields?.displayText}}</a>
          <div class="endInstruction">
            <p>{{cookingClassContent?.fields?.endInstruction}}</p>
          </div>
        </div>
        <div>


        </div>
      </div>
      <div class='cookingClassColumn'>
        <div class="image">
          <img *ngIf="cookingClassContent?.fields?.summaryImage?.fields?.file?.url"
               [alt]="cookingClassContent?.fields?.heading"
               [src]="cookingClassContent?.fields?.summaryImage?.fields?.file?.url"
               class="summaryImage">
        </div>

      </div>
    </div>
    <div *ngIf="cookingClassContent?.fields?.iframeUrl" class="iframeContainer">
      <iframe [src]="safeIframeSrc" allowfullscreen class="iframeContent" frameborder="0" mozallowfullscreen
              webkitallowfullscreen></iframe>
    </div>
  </div>
</section>
<section *ngIf="cookingClasses">

  <div class="moreCookingClasses">
    <h4>JOIN US</h4>
    <h2>More Cooking Classes</h2>
  </div>
  <swiper-container [navigation]="{}"
                    [pagination]="{ clickable: true }"
                    [scrollbar]="{ draggable: true }"
                    [slidesPerView]="3"
                    [spaceBetween]="50"
                    [watchSlidesProgress]="true">
    <swiper-slide *ngFor="let cookingClasses of cookingClasses" class="cardWrapContainer">
      <app-card-layout [cardData]="cookingClasses?.fields?.recipe" class="swiperChild"></app-card-layout>
    </swiper-slide>

  </swiper-container>

</section>

<div class="moreLinkGroup">
  <a class="btn btn-outline-success" href="/cooking-school/cooking-classes" target="_self">See More Classes</a>
</div>
