<nav class="navBar">
  <button (click)="isSlideOutNavOpen = !isSlideOutNavOpen" aria-label="Slide Out Navigation Open" id="toggleMenuBtn">
    <fa-icon [icon]="['fas', 'bars']" aria-hidden="true" aria-label="toggle menu visibility"></fa-icon>
  </button>

  <a [routerLink]="['/']" aria-label="Go to home page">
    <img alt="Food Innovators logo" class="logo" src="assets/icon/food-innovators-logo.svg">
  </a>

  <ul (mouseover)="showNavDropdown=true" class="navItemsContainer">
    <ng-container *ngFor="let item of navItems?.fields?.navigationItems">
      <li *ngIf="showContent(item?.fields?.authenticationRequired)" class="navItem">
        <div [appHandleCMSLink]="item.fields.link">
          {{item.fields.link?.fields?.displayText}}
        </div>

        <!-- the dropdown section of the nav item -->
        <div *ngIf="item?.fields?.dropDownItems && showNavDropdown" class="navDropdown">
          <div class="copyWrapper">
            <div *ngIf="item.fields.dropDownImage?.fields?.file?.url"
              [style.background-image]="'url(' +item.fields.dropDownImage?.fields?.file?.url+ ')'" class="cardImg">
            </div>
          </div>
          <ul class="dropdownLinks">
            <ng-container *ngFor="let dropdownItem of item.fields.dropDownItems">
              <li *ngIf="showContent(dropdownItem?.fields?.authenticationRequired)">
                <a (click)="showNavDropdown = false" [appHandleCMSLink]="dropdownItem">
                  <fa-icon [icon]="['fas', 'chevron-right']" aria-hidden="true"></fa-icon>
                  <span>
                    {{dropdownItem.fields.displayText}}
                  </span>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </li>
    </ng-container>

  </ul>

  <div>
    <div [hidden]="isUserLoggedIn"  id="google-button"></div>
    <button  class="signInButton" (click)="logout()" *ngIf="isUserLoggedIn">
      {{userData.given_name}}
      <span class="logoutIcon"><img [src]="'assets/icon/user-icon.png'" alt="Logout"></span>
    </button>
  </div>
  <form (submit)="search()" class="searchWrapper">
    <label for="search">Search</label>
    <input [(ngModel)]="searchString" name="search" placeholder="Search..." type="text">
    <button aria-label="search" class="searchBtn" type="submit">
      <fa-icon [icon]="['fas', 'search']" aria-hidden="true"></fa-icon>
    </button>
  </form>
</nav>

<!-- slide out navigation -->
<div (click)="isSlideOutNavOpen = false" [class.open]="isSlideOutNavOpen" class="navSlideOutDimmer">

</div>

<nav [class.open]="isSlideOutNavOpen" class="navSlideOut">
  <div class="slideOutNavItemsContainer">
    <button (click)="isSlideOutNavOpen = false" aria-label="closeNav" class="closeBtn">
      <fa-icon [icon]="['fas', 'times']" aria-hidden="true"></fa-icon>
      <span>Close</span>
    </button>
    <ul>
      <li *ngFor="let item of navItems?.fields?.navigationItems" class="slideOutNavItem">
        <a (click)="isSlideOutNavOpen = false" [appHandleCMSLink]="item.fields.link"
          class="categoryName">{{item.fields.dropDownTitle}}</a>

        <ul *ngIf="item.fields?.dropDownItems?.length">
          <li (click)="isSlideOutNavOpen = false" *ngFor="let subItem of item.fields.dropDownItems"
            [appHandleCMSLink]="subItem" class="subLink" role="link">
            {{subItem.fields.displayText}}
          </li>
        </ul>

      </li>
    </ul>
  </div>
</nav>
