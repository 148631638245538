<section>
  <div class="container">
    <div class="boxStyle">
      <h4>
        <span>
          <b>{{homePageContent?.fields?.welcomeTitle}}</b>
        </span>
      </h4>
      <div [appRenderRichText]="homePageContent?.fields?.welcomeBody" class="containerContent">

      </div>
    </div>
  </div>
</section>

<section>
  <div *ngFor="let bodyContent of homePageContent?.fields?.bodyContent">
    <app-home-data *ngIf="showContent(bodyContent?.fields?.authenticationRequired)"
                   [homeContent]="bodyContent"></app-home-data>
  </div>
</section>

<section class="Cooking">
  <div class="CookingHeading">
    <h4>LEARN ESSENTIAL SKILLS</h4>
    <h2>Cooking Classes</h2>
  </div>
  <swiper-container [navigation]="{}"
                    [pagination]="{ clickable: true }"
                    [scrollbar]="{ draggable: true }"
                    [slidesPerView]="3"
                    [spaceBetween]="50"
                    [watchSlidesProgress]="true">

    <swiper-slide *ngFor="let cookingClasses of cookingClasses" class="cardWrapContainer">

      <app-card-layout [ShowButton]="true" [btnLabel]="'Click here for info'"
                       [btnLabel]="'Click here for info'" [cardData]="cookingClasses?.fields?.recipe"
                       class="swiperChild"></app-card-layout>
    </swiper-slide>

  </swiper-container>
</section>

<div class="moreLinkGroup">
  <a class="btn btnOutlineSuccess" href="/cooking-school/cooking-classes" target="_self">+ See More cooking classes</a>
</div>
