import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IFdinCard, IFdinRecipeCard } from '../../interfaces/contentful';

@Component({
  selector: 'app-card-wrapper',
  templateUrl: './card-wrapper.component.html',
  styleUrls: ['./card-wrapper.component.scss']
})
export class CardWrapperComponent {
  @Input() blogPostContent: IFdinCard[] = [];

  @Input() searchResults?: IFdinRecipeCard[] = [];

  @Input() ShowSearchResults?: boolean = false;

  @Input() showBlogPostContent?: boolean = false;

  @Input() btnLabel?: string = '';

  @Input() wrapContent?: boolean = true;

  @Input() parentBackground?: boolean = false;

  @Input() textOverTiles?: boolean = false;

  constructor(
    private router: Router,
  ) {
  }

  navigateToURL(url: any) {
    this.router.navigateByUrl(url);
  }
}
